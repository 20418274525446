import { createTheme, PaletteOptions } from '@mui/material/styles';
import { MixinsOptions } from '@mui/material/styles/createMixins';

import breakpoints from './breakpoints';
import components from './components';
import customMixins from './mixins';
import customPalette from './palette';
import shape from './shape';
import typography from './typography';

const palette = customPalette as PaletteOptions;
const mixins = customMixins as MixinsOptions;

const defaultTheme = createTheme({
  palette,
  typography,
  shape,
  components,
  mixins,
  breakpoints,
});

export default defaultTheme;
