import { FC } from 'react';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';

import { createBrowserHistory } from 'history';

import { AuthProvider } from '~/shared/contexts/Auth';

import { Routes } from '.';

export const history = createBrowserHistory();

const Router: FC = () => (
  <AuthProvider>
    <HistoryRouter history={history}>
      <Routes />
    </HistoryRouter>
  </AuthProvider>
);

export default Router;
