export enum AppRoutes {
  AUTH = 'auth',
  PROSPECTING = 'prospecting',
  FI = 'fi',
  PHOTO = 'photo',
}

export enum ExternalRoutes {
  PROSPECTING = '/prospecting/clients',
  FI = '/fi/main',
  PHOTO = '/photo/search',
}
