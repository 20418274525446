import { styled } from '@mui/material';

export const StyledLoadingLayout = styled('div')`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background.gray};
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
`;
