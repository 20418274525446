import React, { FC, memo } from 'react';

import { CircularProgress } from '@mui/material';

import { StyledLoadingLayout } from './LoadingLayout.styles';

const LoadingLayout: FC = () => (
  <StyledLoadingLayout>
    <CircularProgress />
  </StyledLoadingLayout>
);

export default memo(LoadingLayout);
