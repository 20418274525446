import React from 'react';
import ReactDOM from 'react-dom/client';

import { CacheProvider } from '@emotion/react';
import { ThemeProvider } from '@mui/material';
import * as dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isYesterday from 'dayjs/plugin/isYesterday';
import 'dayjs/locale/pt-br';
import { SnackbarProvider } from 'notistack';

import './index.css';

import { HooklessSnackbarConfigurator } from '~/shared/utils/hooklessSnackbar';

import { cache } from './configs/emotionCaches';
import defaultTheme from './configs/theme';
import reportWebVitals from './reportWebVitals';
import Router from './routes';

// eslint-disable-next-line @typescript-eslint/no-explicit-any

dayjs.extend(isSameOrBefore);
dayjs.extend(isYesterday);
dayjs.locale('pt-br');

const rootElement = document.getElementById('root') as HTMLElement;
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <CacheProvider value={cache}>
        <SnackbarProvider
          preventDuplicate
          style={{
            fontSize: 16,
            borderRadius: 8,
          }}
        >
          <HooklessSnackbarConfigurator />
          <Router />
        </SnackbarProvider>
      </CacheProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
