import React from 'react';

import { useSnackbar, ProviderContext } from 'notistack';

// eslint-disable-next-line import/no-mutable-exports
export let snackbar: ProviderContext;

export const HooklessSnackbarConfigurator: React.FC = () => {
  snackbar = useSnackbar();
  return null;
};
