import React, { useEffect } from 'react';
import {
  Route,
  Routes as ReactRoutes,
  useLocation,
  Navigate,
} from 'react-router-dom';

import { Alert, AlertTitle, Box } from '@mui/material';

import { AppRoutes } from '~/shared/constants/AppRoutes';
import useAuth from '~/shared/contexts/Auth';
import AsyncPage from '~/shared/layouts/AsyncPage';

import { useNavigation } from './hooks/useNavigation';

const Prospecting = AsyncPage({ importPromise: () => import('@Prospecting/index') });
const FI = AsyncPage({ importPromise: () => import('@FI/index') });
const Auth = AsyncPage({ importPromise: () => import('@Auth/index') });
const Photo = AsyncPage({ importPromise: () => import('@Photo/index') });

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function RequireAuth({ children }: { children: any }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  return isAuthenticated ? children : <Navigate
    to="/auth/login"
    replace
    state={{
      path: location.pathname,
    }}
  />;
}

const Routes = () => {
  const { pathname } = useLocation();
  const { navigateTo } = useNavigation();

  useEffect(() => {
    if (pathname === '/') {
      navigateTo(`${AppRoutes.AUTH}/login`);
    }

    if (pathname === `/${AppRoutes.PROSPECTING}`) {
      navigateTo(`${AppRoutes.PROSPECTING}/clients`);
    }

    if (pathname === `/${AppRoutes.PHOTO}`) {
      navigateTo(`${AppRoutes.PHOTO}/search`);
    }
  }, [navigateTo, pathname]);

  return (
    <ReactRoutes>
      <Route path={AppRoutes.AUTH}>
        <Route
          path="*"
          element={<Auth />}
        />
      </Route>

      <Route path={AppRoutes.PROSPECTING}>
        <Route
          path="*"
          element={
            <RequireAuth>
              <Prospecting />
            </RequireAuth>
          }
        />
      </Route>

      <Route path={AppRoutes.FI}>
        <Route
          path="*"
          element={
            <RequireAuth>
              <FI />
            </RequireAuth>
          }
        />
      </Route>

      <Route path={AppRoutes.PHOTO}>
        <Route
          path="*"
          element={
            <RequireAuth>
              <Photo />
            </RequireAuth>
          }
        />
      </Route>

      <Route
        path="*"
        element={
          <Box
            sx={{
              alignItems: 'center',
              display: 'flex',
              height: '100vh',
              justifyContent: 'center',
              width: '100vw',
            }}
          >
            <Alert severity="error">
              <AlertTitle>Error 404</AlertTitle>
              Sorry, page not found! 😔 —
              {' '}
              <strong>Try again!</strong>
            </Alert>
          </Box>
        }
      />
    </ReactRoutes>
  );
};

export default Routes;
