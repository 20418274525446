const chatPalette = {
  chat: {
    controlsArea: {
      background: '#E9ECEE',
    },
    tabs: {
      background: '#D9D9D9',
    },
  },
};

export default chatPalette;
