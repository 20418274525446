import { useCallback, useState } from 'react';
import { To, useNavigate } from 'react-router-dom';

export const useNavigation = (bPath = '') => {
  const [basePath, setBasePath] = useState(bPath);
  const navigate = useNavigate();

  const navigateTo = useCallback(
    (path: To = '') => {
      if (typeof path === 'string') {
        navigate(`/${basePath}${path}`);
      } else {
        navigate({
          pathname: `/${basePath}/${path.pathname}`,
          search: path.search,
          hash: path.hash,
        });
      }
    },
    [basePath, navigate],
  );

  return {
    basePath,
    setBasePath,
    navigateTo,
  };
};
