import React from 'react';

import { StorageKeys } from '~/shared/constants/StorageKeys';
import { getStorage, setStorage } from '~/shared/utils/localStorage';

interface AuthContextState {
  isAuthenticated: boolean;
  login: () => Promise<unknown>;
  logout: () => Promise<unknown>;
}
const authContext = React.createContext<AuthContextState>({
  isAuthenticated: false,
  login: () => new Promise(() => { /* */ }),
  logout: () => new Promise(() => { /* */ }),
});

function useAuth() {
  const storedAuthContext = getStorage<{ isAuthenticated: boolean }>(StorageKeys.AUTH_CONTEXT);
  const [isAuthenticated, setIsAuthenticated] =
    React.useState(storedAuthContext?.isAuthenticated || false);

  const setStoredAuth = (value: boolean) =>
    setStorage(StorageKeys.AUTH_CONTEXT, value, 'isAuthenticated');

  return {
    isAuthenticated,
    login() {
      return new Promise((/* res */) => {
        setIsAuthenticated(true);
        setStoredAuth(true);
        // res();
      });
    },
    logout() {
      return new Promise((/* res */) => {
        setIsAuthenticated(false);
        setStoredAuth(false);
        // res();
      });
    },
  };
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const auth = useAuth();

  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export default function AuthConsumer() {
  return React.useContext(authContext);
}
