const customBreakpoints = {
  values: {
    xxs: 0,
    xs: 345,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1440,
  },
};

export default customBreakpoints;
