const lightboxPalette = {
  lightbox: {
    header: {
      background: '#F0F2F5',
    },
    imagePreview: {
      background: '#F0F2F5',
    },
  },
};

export default lightboxPalette;
