import { TypographyOptions } from '@mui/material/styles/createTypography';

export default {
  h1: {
    color: '#2E2667',
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: '2.5rem',
    letterSpacing: '-1px',
  },
  h2: {
    color: '#2E2667',
    fontSize: '1.5rem',
    fontWeight: 700,
    lineHeight: '2rem',
    letterSpacing: '-1px',
  },
  h3: {
    color: '#2E2667',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    letterSpacing: '-1px',
  },
  h4: {
    color: '#2E2667',
    fontSize: '1.25rem',
    fontWeight: 400,
    letterSpacing: -0.5,
    lineHeight: '1.5rem',
  },
  h5: {
    color: '#2E2667',
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  h6: {
    color: '#2E2667',
    fontSize: '1rem',
    fontWeight: 600,
    lineHeight: '1.25rem',
  },
  button: {
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '1.25rem',
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1rem',
  },
  body2: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '0.875rem',
  },
  caption: {
    fontSize: '0.65rem',
    fontWeight: 400,
    lineHeight: '0.75rem',
  },
} as TypographyOptions;
