import lightboxPalette from '~/configs/theme/palette/components/lightbox';

import chatPalette from './components/chat';
import headerPalette from './components/header';
import customPalette from './customPalette';

export default {
  components: {
    ...headerPalette,
    ...chatPalette,
    ...lightboxPalette,
  },
  ...customPalette,
};
