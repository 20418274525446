import { Components } from '@mui/material';

export default {
  MuiButton: {
    styleOverrides: {
      root: {
        padding: '19px 21px',
        textTransform: 'none',
        fontSize: '1rem',
        boxShadow: 'none',
        ':hover': {
          boxShadow: 'none',
        },
      },
      containedSecondary: {
        color: '#2E2667',
      },
      containedPrimary: {
        fontSize: '0.875rem',
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        color: '#767676',
        textDecorationColor: '#767676',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        boxShadow: 'none',
        border: '1px solid #E8E8E8',
      },
      elevation1: {
        border: 'none',
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        height: '3.5rem',
        width: '3.5rem',
        backgroundColor: '#fff',
        borderRadius: '1rem',
      },
    },
  },
} as Components;
