import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import stylisPx2RemPlugin from 'stylis-px2rem-plugin';

export const cacheNoPrefixer = createCache({
  key: 'noprefixer',
  stylisPlugins: [stylisPx2RemPlugin()],
});

export const cacheWithPrefixer = createCache({
  key: 'prefixer',
  stylisPlugins: [
    prefixer,
    stylisPx2RemPlugin(),
  ],
});

export const cache = process.env.NODE_ENV === 'development'
  ? cacheNoPrefixer : cacheWithPrefixer;
