const customPalette = {
  success: {
    main: '#4CAF50',
    light: '#D5EDD6',
    contrastText: '#FFFFFF',
  },
  warning: {
    main: '#F07001',
    light: '#FBE0C2',
    lightest: '#F57C00',
    contrastText: '#FFFFFF',
    darkest: '#F44336',
  },
  informational: {
    main: '#3F51B5',
    light: '#D4D8EE',
    lightDivider: '#DFE0FF',
    contrastText: '#FFFFFF',
    dark: '#303F9F',
  },
  primary: {
    main: '#5669F7',
    darkest: '#2E2667',
    lightest: '#07ACEF',
    light: '#7F7F7F',
  },
  secondary: {
    main: '#D6DE08',
  },
  background: {
    lightGray: '#F3F5F8',
    gray: '#F6F6F6',
    paper: '#FFFFFF',
    box: '#FAFBFC',
  },
  gray: {
    main: '#D9D9D9',
    light: '#f1f3f4',
    light_2: '#8C8C8C',
    light_3: '#E9ECEE',
    medium: '#c4c4c4',
    divider: '#D4E3F0',
    dark: '#74758C',
    box: '#E8E8E8',
  },
  text: {
    primary: '#74758C',
    gray: '#767676',
    dark: '#2B2B2B',
    secondary: '#2E2667',
    light: '#EEF4F9',
    blue: '#576AF7',
  },
  shadow: {
    darkest: '#2E266755',
    light: '#576AF71A',
  },
  error: {
    main: '#F44336',
    light: '#FDD6D4',
    dark: '#E31B0C',
  },
};

export default customPalette;
