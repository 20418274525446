import React from 'react';

import loadable from '@loadable/component';

import LoadingLayout from '~/shared/layouts/LoadingLayout';

import { AsyncPageProps } from './AsyncPage.types';

const AsyncPage = ({ importPromise }: AsyncPageProps) => loadable(
  importPromise,
  {
    fallback: <LoadingLayout />,
  },
);

export default AsyncPage;
